import { api, i18n } from "@/config"
import { handleFailedResponse } from "@/helpers/common"
import { showSuccessToast } from "@/helpers/toasts"
import { map, filter } from "lodash-es"

const prepareParams = ({ company_markups, ...rest }) => ({
  ...rest,
  company_markups: prepareCompanyMarkpuParams(company_markups)
})

const prepareCompanyMarkpuParams = companyMarkups => {
  const filtered = filter(companyMarkups, "_updated")
  return map(filtered, ({ fee, ...rest }) => ({ ...rest, ...fee }))
}

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }) => {
    try {
      const { data } = await api.get(baseURI)
      commit("SET_ITEM", data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  UPDATE_ITEM: async ({ commit }, params) => {
    try {
      const { data } = await api.put(baseURI, { otas_markup_setting: prepareParams(params) })
      commit("SET_ITEM", data.data)
      showSuccessToast({ text: i18n.t("ota_management.company_markups_updated") })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
